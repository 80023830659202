import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/crypto-app/Banner';
import Whatis from '../components/crypto-app/Whatis';
import WhyChoose from '../components/crypto-app/WhyChoose';
import FaqSection from '../components/crypto-app/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import TechStack from '../components/crypto-app/TechStack';
import WeProvide from '../components/crypto-app/WeProvide';
import BenifitsOf from '../components/crypto-app/BenifitsOf';
import CommonFeature from '../components/crypto-app/CommonFeature';
import MakeProfite from '../components/crypto-app/MakeProfite';
import ProcessOf from '../components/crypto-app/ProcessOf';
import HireOur from '../components/crypto-app/HireOur';

const CryptoApp = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/cryptocurrency-app-development-company/" />
        <title>Cryptocurrency App Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is a reputed cryptocurrency app development company that provides cutting-edge mobile applications for all kinds of crypto-related businesses within a limited budget." />
        <meta name="keywords" content="Cryptocurrency App Development Company, Cryptocurrency App Development Services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cryptocurrency App Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is a reputed cryptocurrency app development company that provides cutting-edge mobile applications for all kinds of crypto-related businesses within a limited budget." />
        <meta property="og:url" content="https://www.coinsclone.com/cryptocurrency-app-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/crypto-app/crypto-payment-gateway-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a reputed cryptocurrency app development company that provides cutting-edge mobile applications for all kinds of crypto-related businesses within a limited budget." />
        <meta name="twitter:title" content="Cryptocurrency App Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/crypto-app/crypto-payment-gateway-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Cryptocurrency App Development Company</span>
        </div>
      </div>
      <Whatis />
      <WeProvide />
      <BenifitsOf />
      <CommonFeature />
      <MakeProfite />
      <ProcessOf />
      <WhyChoose />
      <TechStack />
      <HireOur />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default CryptoApp
