import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const ProcessOf = () => {


  return (
    <section className="customs crypapp pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Process Of</span> Our Cryptocurrency App Development
            </h2>
            <p className='text-center'>Launch a lightning-speed cryptocurrency app by acquiring a well-planned development process from our development team.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Ideation</h3>
                    <p class="pharagraph">Empower your Vision into Victory with our cryptocurrency app development ideas, and roadmap. We help you to frame your ideologies at the initial stage of the development process to be more clear.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Resource Gathering</h3>  
                    <p class="pharagraph">Once we have framed the ideologies, our team tends to collect the necessary resources for developing the crypto app project.  We also analyze the current market trend to make your cryptocurrency app an exponential solution.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Designing</h3>
                    <p class="pharagraph">We also guide you to integrate attractive and creative design solutions for your cryptocurrency app development. We work on the hustle to deliver a hassle-free cryptocurrency application for you.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Development</h3>
                    <p class="pharagraph">Our team develops smart contract codes, programming codes, and plugins for your cryptocurrency app. The testing team rectifies the technical errors and bugs at the initial stage to ensure quality.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Launch</h3>
                    <p class="pharagraph">After the development process, we help to launch your cryptocurrency app in the ideal market. You can seek help from our developers to upgrade the cryptocurrency application software occasionally.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Monitoring</h3>
                    <p class="pharagraph">Our developer and tester team offers monitoring services for your cryptocurrency app. This is to enhance the quality of the cryptocurrency application with enhanced features and plugins. We also offer support even after the successful launch.</p>
                </div>
            </div>
        </div>
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default ProcessOf