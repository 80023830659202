import React from "react"

class HireOur extends React.Component {

  render() {
    return (

      <section className="benifor kickoff crypapp mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Hire Our</span> Cryptocurrency App Developers
            </h2>
            <p className="text-center">
            Our cryptocurrency app developers encompass a range of tailored solutions to meet the diverse needs, requirements, and specifications of our clients.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square">
                <h3>Contact Our Team</h3>
                <p className="pharagraph">
                Complete our submission form and book a Zoom meeting with our sales team to get relevant details.
                </p>
              </div>
              <div className="square">
                <h3>Seek Consultation</h3>
                <p className="pharagraph">
                Talk to our developers, and experts to kick off your project without any hassles.
                </p>
              </div>
              <div className="square">
                <h3>Get the Cost Details</h3>
                <p className="pharagraph">
                Depending on your ideas, needs, and specifications we frame the cost estimation of your project that suits your business. 
                </p>
              </div>
              <div className="square">
                <h3>Begin your Project</h3>
                <p className="pharagraph">
                After signing with us, as soon as possible we began the work to develop your project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default HireOur
