import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">What Makes Coinsclone </span> The Best Cryptocurrency App Development Company?</h2>
              <p className="pharagraph">Coinsclone is a prominent Cryptocurrency App development company that comprises well-versed developers and experts. We strategize our development services as per the client’s business needs and requirements. Our developer, technician, and designing team is highly experienced in blockchain and has delivered numerous blockchain development projects.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-app/what-makes-crypto-payment.webp"
                alt="best crypto app development company image1"
                placeholder='none'
                className='mt-3 mb-3'
                width={381}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">We prioritize quality over quantity to fulfill our client’s needs and utilize advanced-level security mechanisms. Our team assures you that we complete the projects within the given time at an affordable cost for crypto-enthusiastic entrepreneurs. We also offer continuous security upgrade and maintenance services for our cryptocurrency app development projects. What we provide for our clients are...
              </p>
              <ul className='mt-3'>
                <li>Catchy Interface Development</li>
                <li>Pro-longed support</li>
                <li>Agile Software solutions</li>
                <li>Flexible Scalability Options</li>
                <li>24/7 Availability</li>
              </ul>
              <p className="pharagraph mt-2">Embrace the extraordinary with an exponential team!!</p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose