import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">Cryptocurrency App Development Company - <span className='bluecolor'>An Overview</span> </h2>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone is a prominent cryptocurrency app development company in the blockchain universe that has helped many startups succeed in the crypto sector. With our cryptocurrency app development, you can create any relevant business that reaps immense revenue. Our developers, designers, and technicians are well-versed in utilizing a modern technology stack to offer you the best outcome.
              </p>
              <p className="pharagraph text-center mb-0">Also, our developers help you to redefine your business possibilities and capabilities to success. We assure you that we will offer premium development services with utmost perfection at a reasonable price. Without any secondary thoughts, hire our technical team and lay stepping stones for your business empire.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis