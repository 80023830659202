import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is Cryptocurrency app development?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Cryptocurrency app development refers to creating mobile applications that ease the management, usage, and smooth transactions of cryptocurrencies. The cryptocurrency application ensures the creation of exchanges, wallets, NFT marketplaces, and payment gateways.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How much does it cost to develop a cryptocurrency app?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The approximate cost to develop a cryptocurrency app ranges between $20,000 to $35,000. With various factors like customization, feature integration, and API implementations, the cost may vary. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How long does it take to develop a crypto app?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The average time to develop a cryptocurrency app may take 3 to 5 months. The time may differ, based on your specifications for creating a crypto app with potential features.  
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What are the potential benefits of investing in cryptocurrency app development? 
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The potential benefits of investing in cryptocurrency app development will help you reach a wider set of audience, cost-effective solutions to reap more ROI, etc. If you are a crypto-enthusiastic startup, then cryptocurrency application development will be the best option.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                Can a cryptocurrency app development company help with blockchain integration?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Yes, Of course, they will help you to build your cryptocurrency app into your choice of blockchain technology.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                How to Hire The Best Cryptocurrency App Development Company?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">First, analyze the crypto market and choose the best cryptocurrency app development company based on various factors like,
                <ul>
                  <li>Technical Expertise</li>
                  <li>Client Testimonials</li>
                  <li>Past portfolio</li>
                  <li>Years of experience in the blockchain industry</li>
                </ul>
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection