import React from 'react'


const BenifitsOf = () => {

  return (
    <section className="typeof crypapp pt-100 mb-0">
      <div className='gray-bg'>
        <div className="container">
          <div className="row">
              <h2 className="heading-h2 text-center"><span className="bluecolor">Benefits Of</span> Our Cryptocurrency App Development
              </h2>
              <p className="text-center">
              Ignite your passion with the cryptocurrency app development that offers numerous business benefits for entrepreneurs.
              </p>
          </div>
          <div className='row popular we-offer'>
              <div className='col-md-12 col-lg-3'>
                  <h3>Optimizable</h3>
                  <p className='pharagraph'>Our development team helps you to customize the tech stack, UI/UX designs, themes, security features, functionalities, and user/admin features. So that you can turn your vision into reality. </p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Cost-Effective Solutions</h3>
                  <p className='pharagraph'>We offer all the cryptocurrency application development services at a reasonable cost that suits startups’ budgets. Our team offers various solutions for developing a cryptocurrency app.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Higher ROI</h3>
                  <p className='pharagraph'>We designed our cryptocurrency app development with higher revenue-yielding streams and modules. We lead you toward the purpose of generating more revenue through listing, trading, deposit, and withdrawal fees.</p>
              </div>
              <div className='col-md-12 col-lg-3'>
                  <h3>Global Outreach</h3>
                  <p className='pharagraph'>Our cryptocurrency application helps you reach out to all kinds of users. Experience world-class cryptocurrency application development services with security and advanced-level transparency.</p>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BenifitsOf