import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'

class CommonFeature extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="bluecolor">Common Features </span>Of Our Cryptocurrency App</h2>
            <p className='text-center mw1030'>We leverage your cryptocurrency application development with exponential user and admin features at your choice of customizations.</p>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                Multi-Layer Security
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                Compatibility
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                Conversion Rates
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                KYC/AML Factors
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                Catchy User Interface
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs6')} >
                Referral Programs
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs7')} >
                Higher-level Privacy
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs8')} >
                Customer Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs9')} >
                Real-Time Market Data
                </li>
              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Multi-Layer Security
                      </h3>
                      <p className="pharagraph">Our development team ensures to offer higher-level security protocols into the cryptocurrency app as a precautious measure. Your users can transact digital assets without fearing hacks or scams.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/multi-layer-security.webp"
                      alt="Multi-Layer Security image1"
                      width={309}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Compatibility
                      </h3>
                      <p className="pharagraph">Our cryptocurrency app allows your users to trade cryptocurrencies from any blockchain network. This enhances the rapid transactions within the platform without any technical bugs or errors.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/compatibility.webp"
                      alt="Compatibility image1"
                      width={311}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Conversion Rates
                      </h3>
                      <p className="pharagraph">Our development team indulges automatic conversion rates for both cryptocurrencies as well as fiat currencies. Multiple filter options are provided for crypto investors to make their work more convenient.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/conversion-rate.webp"
                      alt="Conversion Rates image1"
                      width={408}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">KYC/AML Factors</h3>
                      <p className="pharagraph">As we strive for excellence in every aspect, we leverage your cryptocurrency app with protective KYC/AML factors. We strive to create a legitimate and trustworthy platform for cryptocurrency transactions. 
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/kyc-aml-factors.webp"
                      alt="KYC/AML Factors image1"
                      width={353}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Catchy User Interface
                      </h3>
                      <p className="pharagraph">Our design team unlocks the unlimited potential to offer an attractive and eye-catching user dashboard. We tend to create a cryptocurrency app with easy navigation and less complex features.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/catchy-user-interface.webp"
                      alt="Catchy User Interface image1"
                      width={264}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs6" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Referral Programs</h3>
                      <p className="pharagraph">As a part of the marketing trick, we help you design numerous referral programs, vouchers, gift cards, and scratch cards. This enhances the user count for your cryptocurrency app and increases brand recognition.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/referral-programs.webp"
                      alt="Referral Programs image1"
                      width={248}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs7" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Higher-level Privacy </h3>
                      <p className="pharagraph">We assure you that our cryptocurrency application development will come with specific privacy protocols. Through this, we ensure to protect the user data and information from fraud, and hacking.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/higher-level-privacy.webp"
                      alt="Higher-level Privacy image1"
                      width={303}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs8" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Customer Support</h3>
                      <p className="pharagraph">Our cryptocurrency app will offer your users 24/7 availability for customer support. You can help your users raise tickets regarding technical or transactional issues to get rectified as soon as possible.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/customer-support.webp"
                      alt="Customer Support image1"
                      width={272}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs9" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">Real-Time Market Data</h3>
                      <p className="pharagraph">Our team integrates potential market data charts for easy trading of crypto users. We also utilize high-tech features to send push notifications for crypto users regarding price alerts, and new crypto arrivals. 
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/crypto-app/real-time-market-data.webp"
                      alt="Real-Time Market Data image1"
                      width={375}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CommonFeature